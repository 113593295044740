import { isEmpty } from 'ramda';
import ObjectID from 'bson-objectid';
import React from 'react';

import {
  ActionAttribute,
  CriteriaOperator,
  CriteriaRule,
  ISurveyLogicQuestion,
  ISurveyQuestionSet,
  QuestionAnswerChoice,
  SkipLogicCriteriaOption,
  SurveyQuestionType,
  CriteriaKey,
} from 'src/types/core';
import { Button, DropdownOption } from 'src/components/molecules';
import { isType } from 'src/reducers/surveys-v12/surveys.utils';
import { MultiAppsSurvey } from 'src/reducers/surveys-multi-apps';

import { SkipLogicStatement } from './skip-logic-v12';

export interface RangeOrNpsAnswerOption {
  value: number;
  label: string;
  id: string;
}

export enum ComporatorEnum {
  is = 'Is',
  is_not = 'Is Not',
  is_more_than = 'Is More Than',
  is_less_than = 'Is Less Than',
  is_at_most = 'Is At Most',
  is_at_least = 'Is At Least',
  is_between = 'Is Between',
}

export enum NpsAnswersEnum {
  is_nps_promoters = 'Promoters (9-10)',
  is_nps_passives = 'Passives (7-8)',
  is_nps_detractors = 'Detractors (0-6)',
}

export interface SkipLogicCriteria {
  id: string;
  answerOption: SkipLogicCriteriaOption;
  nextQuestionSet: string;
}

interface GroupValues {
  is_gte: number;
  is_lte: number;
  is_not_gte: number;
  is_not_lte: number;
}

export const DEFAUL_STATEMENT = {
  comparatorOption: {
    id: '',
    value: ComporatorEnum.is,
    label: ComporatorEnum.is,
  },
  answerOption: {
    id: '',
    value: '',
    label: '',
  },
  questionSetOption: {
    id: '',
    value: '',
    label: '',
  },
};

export const IS_ANSWERED_OPTION = {
  value: 'IS_ANSWERED_OPTION',
  label: 'Answered',
};

export const IS_END_OPTION = {
  value: 'IS_END_OPTION',
  label: 'Skip to thank you message (end of survey)',
};

export const NPS_ANSWER_OPTIONS = [
  {
    value: NpsAnswersEnum.is_nps_promoters,
    label: NpsAnswersEnum.is_nps_promoters,
    id: NpsAnswersEnum.is_nps_promoters,
  },
  {
    value: NpsAnswersEnum.is_nps_passives,
    label: NpsAnswersEnum.is_nps_passives,
    id: NpsAnswersEnum.is_nps_passives,
  },
  {
    value: NpsAnswersEnum.is_nps_detractors,
    label: NpsAnswersEnum.is_nps_detractors,
    id: NpsAnswersEnum.is_nps_detractors,
  },
];

export const NPS_GROUP_VALUES: Record<string, GroupValues> = {
  [NpsAnswersEnum.is_nps_detractors]: {
    is_gte: 0,
    is_lte: 6,
    is_not_gte: 7,
    is_not_lte: 10,
  },
  [NpsAnswersEnum.is_nps_passives]: {
    is_gte: 7,
    is_lte: 8,
    is_not_gte: 9,
    is_not_lte: 6,
  },
  [NpsAnswersEnum.is_nps_promoters]: {
    is_gte: 9,
    is_lte: 10,
    is_not_gte: 0,
    is_not_lte: 8,
  },
};

export const isDefaultInvoke = (inv: ActionAttribute) =>
  !!(inv.criteria && Object.keys(inv.criteria).filter((el) => el !== '_version').length === 0);
export const isNotDefaultInvoke = (inv: ActionAttribute) =>
  !!(inv.criteria && Object.keys(inv.criteria).filter((el) => el !== '_version').length > 0);
export const isActiveNotDefaultInvoke = (inv: ActionAttribute) => isNotDefaultInvoke(inv) && inv._destroy !== 1;

export const isActiveQuestion = (q: ISurveyQuestionSet) => q._destroy !== 1;

export const getIsSurveyV12WithSkipToEnd = (survey: MultiAppsSurvey): boolean =>
  survey.questionSetAttributes.some(
    (qSet: ISurveyQuestionSet) =>
      !!qSet.invokes &&
      !isEmpty(qSet.invokes.filter(isActiveNotDefaultInvoke)) &&
      qSet.invokes.some((inv) => inv.behavior === 'end' && isNotDefaultInvoke(inv) && !inv.nextLogicalId),
  );

export const getIsQuestionSetWithSkipLogic = (qSet: ISurveyQuestionSet) =>
  !!qSet.invokes &&
  !isEmpty(qSet.invokes.filter(isActiveNotDefaultInvoke)) &&
  (isType.Multichoice(qSet.questions[0]) ||
    isType.Multiselect(qSet.questions[0]) ||
    isType.Singleline(qSet.questions[0]) ||
    isType.Range(qSet.questions[0]) ||
    isType.Nps(qSet.questions[0]));

export const getIsSurveyV12WithSkipLogic = (survey: MultiAppsSurvey): boolean =>
  survey.questionSetAttributes.filter(isActiveQuestion).some(getIsQuestionSetWithSkipLogic);

export const isSurveyV12WithSkipLogic = (survey?: MultiAppsSurvey): boolean => {
  if (!survey) {
    return false;
  }

  return survey.questionSetAttributes.filter(isActiveQuestion).some(getIsQuestionSetWithSkipLogic);
};

export const getRangeNpsAnswOptions = (min: number, length: number) =>
  ((max) => {
    const values = [];
    while (max > min) values.unshift(max--); // eslint-disable-line
    return values;
  })(min + length).map((value) => ({ value, label: String(value), id: String(value) }));

const parseComparator = (comparator: CriteriaOperator, value = false): ComporatorEnum => {
  if (comparator === CriteriaOperator.$exists) {
    return value ? ComporatorEnum.is : ComporatorEnum.is_not;
  }
  switch (comparator) {
    case CriteriaOperator.$ne:
      return ComporatorEnum.is_not;
    case CriteriaOperator.$eq:
      return ComporatorEnum.is;
    case CriteriaOperator.$gt:
      return ComporatorEnum.is_more_than;
    case CriteriaOperator.$lte:
      return ComporatorEnum.is_at_most;
    case CriteriaOperator.$gte:
      return ComporatorEnum.is_at_least;
    case CriteriaOperator.$lt:
      return ComporatorEnum.is_less_than;
    default:
      return ComporatorEnum.is_not;
  }
};

// from {'interactions/some-id/current_answer/value': {'$eq': 'answer-choice-id'}} ==> get {'$eq': 'answer-choice-id'}
const getCriteriaRule = (criteria: SkipLogicCriteriaOption): CriteriaRule => Object.values(criteria)[0];

// from {'$eq': 'answer-choice-id'} ==> get '$eq'
const getCriteriaRuleKey = (criteriaRule: CriteriaRule) => Object.keys(criteriaRule)[0];

// from {'$eq': 'answer-choice-id'} ==> get 'answer-choice-id'
const getCriteriaRuleValue = (criteriaRule: CriteriaRule) => Object.values(criteriaRule)[0];

const getSkipLogicCriteriaOptions = (criteria: SkipLogicCriteria): SkipLogicCriteriaOption[] => {
  const rootCriteria = Object.keys(criteria.answerOption)[0];
  const hasMultipleValues = rootCriteria === CriteriaOperator.$and || rootCriteria === CriteriaOperator.$or;
  return hasMultipleValues ? (Object.values(criteria.answerOption)[0] as any) : [{ ...criteria.answerOption }];
};

const getComporatorFromAnswObj = (answOption: SkipLogicCriteriaOption) => {
  const firstRule = getCriteriaRule(answOption);
  return getCriteriaRuleKey(firstRule);
};

const getAnswOptionFromAnswObj = (answOption: SkipLogicCriteriaOption, isBetween = false) => {
  const firstRule = getCriteriaRule(answOption);

  if (isBetween) {
    return Number(getCriteriaRuleValue(firstRule)) - 1;
  }
  return getCriteriaRuleValue(firstRule);
};

const getNpsRangeComporator = (criteriaObjs: SkipLogicCriteriaOption[]) => {
  const firstRule = getCriteriaRule(criteriaObjs[0]);
  const secondRule = getCriteriaRule(criteriaObjs[1]);

  const firstCriteria = getCriteriaRuleKey(firstRule);
  const secondCriteria = getCriteriaRuleKey(secondRule);

  const hasBetweenCriteria = firstCriteria === CriteriaOperator.$gt && secondCriteria === CriteriaOperator.$lt;
  const hasNpsIs = firstCriteria === CriteriaOperator.$gte && secondCriteria === CriteriaOperator.$lte;

  if (!hasBetweenCriteria) {
    return hasNpsIs ? ComporatorEnum.is : ComporatorEnum.is_not;
  }

  return ComporatorEnum.is_between;
};

const getNpsRangeAnswValue = (criteriaObjs: SkipLogicCriteriaOption[], comparator: string) => {
  // firstValue is $gte and secondValue is $lte for ComporatorEnum.is
  // firstValue is $lte and secondValue is $gte for ComporatorEnum.is_not
  const firstRule = getCriteriaRule(criteriaObjs[0]);
  const secondRule = getCriteriaRule(criteriaObjs[1]);

  const firstValue = +getCriteriaRuleValue(firstRule);
  const secondValue = +getCriteriaRuleValue(secondRule);

  const hasBetweenCriteria =
    getCriteriaRuleKey(firstRule) === CriteriaOperator.$gt && getCriteriaRuleKey(secondRule) === CriteriaOperator.$lt;

  if (hasBetweenCriteria) {
    return String(firstValue + 1);
  }

  if (comparator === ComporatorEnum.is) {
    if (firstValue >= NPS_GROUP_VALUES[NpsAnswersEnum.is_nps_promoters].is_gte) {
      return NpsAnswersEnum.is_nps_promoters;
    }
    if (secondValue <= NPS_GROUP_VALUES[NpsAnswersEnum.is_nps_detractors].is_lte) {
      return NpsAnswersEnum.is_nps_detractors;
    }
    return NpsAnswersEnum.is_nps_passives;
  }

  if (
    secondValue >= NPS_GROUP_VALUES[NpsAnswersEnum.is_nps_promoters].is_gte &&
    firstValue <= NPS_GROUP_VALUES[NpsAnswersEnum.is_nps_passives].is_gte
  ) {
    return NpsAnswersEnum.is_nps_passives;
  }
  if (firstValue <= NPS_GROUP_VALUES[NpsAnswersEnum.is_nps_promoters].is_gte) {
    return NpsAnswersEnum.is_nps_promoters;
  }
  return NpsAnswersEnum.is_nps_detractors;
};

const convertCriteriaToStmt = (
  criteria: SkipLogicCriteria,
  index: number,
  answerLabelsMap: Map<string, string>,
): SkipLogicStatement => {
  if (!criteria) {
    return { ...DEFAUL_STATEMENT, key: ObjectID().toHexString(), label: '' };
  }

  let answerLabel = '';
  let answerValue = '';
  let addAnswerValue = 0;
  let comparatorLabel = '';
  const questionOp = criteria.nextQuestionSet;

  // get answer options array like: [{'interactions/some-id/current_answer/value': {'$eq': 'answer-choice-id'}}, {...}]
  const arrayOfAnswerOptions = getSkipLogicCriteriaOptions(criteria);
  const comparator = getComporatorFromAnswObj(arrayOfAnswerOptions[0]);
  const answerOption = getAnswOptionFromAnswObj(arrayOfAnswerOptions[0]);

  // define if answer options array has several values it means that one skip logic criteria consists of multiple conditions
  const hasMultipleAnswOptions = arrayOfAnswerOptions.length > 1;
  if (hasMultipleAnswOptions) {
    // handle case with criteria consists of multiple conditions like: [{'interactions/some-id/current_answer/value':{'$gte': 0}}, {'interactions/some-id/current_answer/value':{'$lte': 6}}]
    const comparator = getNpsRangeComporator(arrayOfAnswerOptions);
    comparatorLabel = comparator;
    answerLabel = getNpsRangeAnswValue(arrayOfAnswerOptions, comparatorLabel);
    answerValue = getNpsRangeAnswValue(arrayOfAnswerOptions, comparatorLabel);
    addAnswerValue =
      comparator === ComporatorEnum.is_between
        ? (getAnswOptionFromAnswObj(arrayOfAnswerOptions[1], true) as number)
        : 0;
  } else if (comparator === CriteriaOperator.$exists) {
    // handle case when criteria is like: [{'$exists': true}] ==> is_answered/is_not_answered
    comparatorLabel = parseComparator(comparator as CriteriaOperator, !!answerOption);
    answerLabel = IS_ANSWERED_OPTION.label;
    answerValue = IS_ANSWERED_OPTION.value;
  } else {
    // handle case when criteria is like: [{'$eq': 'answer-choice-id'}]
    comparatorLabel = parseComparator(comparator as CriteriaOperator);
    answerValue = isEmpty(getCriteriaRule(arrayOfAnswerOptions[0])) ? '' : (answerOption as string);
    answerLabel = answerLabelsMap.get(answerValue) || '';
  }

  return {
    key: criteria.id,
    label: `Skip Logic Statement ${index + 1}`,
    answerOption: {
      id: criteria.id,
      value: answerValue,
      label: answerLabel,
      add_value: addAnswerValue,
    },
    questionSetOption: {
      id: criteria.id,
      value: questionOp,
      label: '',
    },
    comparatorOption: {
      id: criteria.id,
      value: comparatorLabel,
      label: comparatorLabel,
    },
  };
};

export const getCriteriasFromQSetV12 = (QSet: ISurveyQuestionSet): SkipLogicCriteria[] => {
  let criterias: SkipLogicCriteria[] = [];
  const invokes = QSet.invokes ? QSet.invokes.filter(isNotDefaultInvoke) : [];

  // iterate throught skip logic criterias fetched from backend
  invokes.forEach((invoke) => {
    if (!invoke.criteria) {
      return;
    }

    criterias = [
      ...criterias,
      {
        id: invoke.id || ObjectID().toHexString(),
        answerOption: invoke.criteria,
        nextQuestionSet: invoke.behavior === 'end' ? IS_END_OPTION.value : (invoke.nextLogicalId as string),
      },
    ];
  });

  return criterias;
};

export const getSkipLogicStmtsFromQSetV12 = (
  hasAnswerChoices: boolean,
  questionSet: ISurveyQuestionSet,
): SkipLogicStatement[] => {
  if (
    !questionSet ||
    !questionSet.invokes ||
    isEmpty(questionSet.invokes.filter(isNotDefaultInvoke)) ||
    isEmpty(questionSet.questions)
  ) {
    return [];
  }

  // store question answers in map where key = anwer_id, value = answer_value
  const answerLabelsMap: Map<string, string> = new Map();
  const answChoices = questionSet.questions[0].answerChoices as QuestionAnswerChoice[];

  if (!answChoices && !hasAnswerChoices) {
    return [];
  }

  answChoices && answChoices.forEach((ans) => answerLabelsMap.set(ans.branchingId as string, ans.value));

  // get skip logic criterias from questionSet
  const criterias = getCriteriasFromQSetV12(questionSet);

  // return ui friendly skip logic statements converted from skip logic criterias
  return criterias.map((crtiria, index) =>
    convertCriteriaToStmt(crtiria, index, answerLabelsMap),
  ) as SkipLogicStatement[];
};

const getCriteriaFromStmt = (stmt: SkipLogicStatement): CriteriaRule => {
  if (stmt.answerOption.value === IS_ANSWERED_OPTION.value) {
    return {
      [CriteriaOperator.$exists]: stmt.comparatorOption.value === ComporatorEnum.is,
    } as CriteriaRule;
  }

  switch (stmt.comparatorOption.value) {
    case ComporatorEnum.is:
      return {
        [CriteriaOperator.$eq]: stmt.answerOption.value,
      } as CriteriaRule;
    case ComporatorEnum.is_not:
      return {
        [CriteriaOperator.$ne]: stmt.answerOption.value,
      } as CriteriaRule;

    case ComporatorEnum.is_less_than:
      return {
        [CriteriaOperator.$lt]: stmt.answerOption.value,
      } as CriteriaRule;
    case ComporatorEnum.is_more_than:
      return {
        [CriteriaOperator.$gt]: stmt.answerOption.value,
      } as CriteriaRule;
    case ComporatorEnum.is_at_least:
      return {
        [CriteriaOperator.$gte]: stmt.answerOption.value,
      } as CriteriaRule;
    case ComporatorEnum.is_at_most:
      return {
        [CriteriaOperator.$lte]: stmt.answerOption.value,
      } as CriteriaRule;
    default:
      return {
        [CriteriaOperator.$eq]: '',
      } as CriteriaRule;
  }
};

const getNpsRangeCriteriaFromStmt = (
  qSetID: string,
  stmt: SkipLogicStatement,
): SkipLogicCriteriaOption | Record<string, SkipLogicCriteriaOption[]> => {
  let hasComporatorIsOption = true;
  let firstComporator: string = CriteriaOperator.$gt;
  let secondComporator: string = CriteriaOperator.$lt;
  let firstValue = stmt.answerOption.value === '' ? null : Number(stmt.answerOption.value) - 1;
  let secondValue = Number.isNaN(Number(stmt.answerOption.add_value)) ? null : Number(stmt.answerOption.add_value) + 1;
  const groupValue = stmt.answerOption.value;
  const isBetweenComporator = stmt.comparatorOption.value === ComporatorEnum.is_between;

  if (
    groupValue !== NpsAnswersEnum.is_nps_detractors &&
    groupValue !== NpsAnswersEnum.is_nps_passives &&
    groupValue !== NpsAnswersEnum.is_nps_promoters &&
    !isBetweenComporator
  ) {
    return {
      [`interactions/${qSetID}/current_answer/value`]: getCriteriaFromStmt(stmt),
    } as SkipLogicCriteriaOption;
  }

  if (!isBetweenComporator) {
    hasComporatorIsOption = stmt.comparatorOption.value === ComporatorEnum.is;
    firstComporator = `${hasComporatorIsOption ? CriteriaOperator.$gte : CriteriaOperator.$lte}`;
    secondComporator = `${hasComporatorIsOption ? CriteriaOperator.$lte : CriteriaOperator.$gte}`;
    firstValue = hasComporatorIsOption ? NPS_GROUP_VALUES[groupValue].is_gte : NPS_GROUP_VALUES[groupValue].is_not_lte;
    secondValue = hasComporatorIsOption ? NPS_GROUP_VALUES[groupValue].is_lte : NPS_GROUP_VALUES[groupValue].is_not_gte;
  }

  const rootCriteriaComporator =
    stmt.comparatorOption.value === ComporatorEnum.is_not && groupValue === NpsAnswersEnum.is_nps_passives
      ? CriteriaOperator.$or
      : CriteriaOperator.$and;
  return {
    [rootCriteriaComporator]: [
      {
        [`interactions/${qSetID}/current_answer/value`]: {
          [firstComporator as CriteriaKey]: firstValue,
        } as CriteriaRule,
      } as SkipLogicCriteriaOption,
      {
        [`interactions/${qSetID}/current_answer/value`]: {
          [secondComporator as CriteriaKey]: secondValue,
        } as CriteriaRule,
      } as SkipLogicCriteriaOption,
    ],
  };
};

const getSkipLogicInstructions = (
  question: ISurveyLogicQuestion,
  stmt: SkipLogicStatement,
): Record<string, CriteriaRule> => {
  const isSingleline = isType.Singleline(question);
  const isRange = isType.Range(question);
  const isNps = isType.Nps(question);

  if (isRange || isNps) {
    return { ...getNpsRangeCriteriaFromStmt(question.logicalId as string, stmt) } as Record<string, CriteriaRule>;
  }

  return {
    [`interactions/${question.logicalId}/current_answer/${isSingleline ? 'value' : 'id'}`]: getCriteriaFromStmt(stmt),
  };
};

export const getQSetWithSkipLogicV12 = (
  questionSet: ISurveyQuestionSet,
  stmts: SkipLogicStatement[],
): ISurveyQuestionSet => {
  if (!questionSet.questions[0]) {
    return questionSet;
  }

  const question = questionSet.questions[0];
  const invokeIds: string[] = [];
  const invokeIdsMap = new Map<string, boolean>();
  questionSet.invokes?.filter(isNotDefaultInvoke).forEach((inv) => {
    if (!inv.id) {
      return;
    }
    invokeIdsMap.set(inv.id, false);
    invokeIds.push(inv.id);
  });

  const newInvokes = stmts.map((stmt, index) => {
    // update invoke if it is alredy exists
    if (invokeIdsMap.has(stmt.key)) {
      invokeIdsMap.set(stmt.key, true);
      return {
        ...questionSet.invokes?.find((inv) => inv.id === stmt.key),
        behavior: stmt.questionSetOption.value === IS_END_OPTION.value ? 'end' : 'continue',
        order: index,
        nextLogicalId: stmt.questionSetOption.value === IS_END_OPTION.value ? '' : stmt.questionSetOption.value,
        criteria: getSkipLogicInstructions(question, stmt),
      } as ActionAttribute;
    }

    // otherwise create entire new invoke
    return {
      order: index,
      behavior: stmt.questionSetOption.value === IS_END_OPTION.value ? 'end' : 'continue',
      nextLogicalId: stmt.questionSetOption.value === IS_END_OPTION.value ? '' : stmt.questionSetOption.value,
      criteria: getSkipLogicInstructions(question, stmt),
    } as ActionAttribute;
  });

  // check if some invokes were removed then _destroy flag should be set
  invokeIds.forEach((invokeId) => {
    if (!invokeIdsMap.get(invokeId)) {
      const removedInvoke = questionSet.invokes?.find((inv) => inv.id && inv.id === invokeId);
      if (removedInvoke) {
        newInvokes.push({ ...removedInvoke, _destroy: 1 });
      }
    }
  });

  return {
    ...questionSet,
    invokes:
      questionSet.invokes && questionSet.invokes.some(isDefaultInvoke)
        ? [...newInvokes, questionSet.invokes?.find(isDefaultInvoke) as ActionAttribute]
        : newInvokes,
  };
};

// find questionSet name by id
export const getQSetNameByIdV12 = (id: string, questionSets: ISurveyQuestionSet[]) => {
  const qSet = questionSets.find((qSet) => qSet.logicalId === id);
  return qSet && qSet.questions ? qSet.questions[0].value : '';
};

export const getCriteriaOptions = (): DropdownOption[] => [
  {
    label: ComporatorEnum.is,
    value: ComporatorEnum.is,
  },
  {
    label: ComporatorEnum.is_not,
    value: ComporatorEnum.is_not,
  },
];

export const getCriteriaOptionsRangeAndNps = (): DropdownOption[] => [
  {
    label: ComporatorEnum.is_between,
    value: ComporatorEnum.is_between,
  },
  {
    label: ComporatorEnum.is_more_than,
    value: ComporatorEnum.is_more_than,
  },
  {
    label: ComporatorEnum.is_less_than,
    value: ComporatorEnum.is_less_than,
  },
  {
    label: ComporatorEnum.is_at_most,
    value: ComporatorEnum.is_at_most,
  },
  {
    label: ComporatorEnum.is_at_least,
    value: ComporatorEnum.is_at_least,
  },
];

// get question options for skip logic statement dropdown from survey-v12
export const getQuestionOptionsV12 = (
  survey: MultiAppsSurvey,
  optionId: string,
  num: number,
  hasRichTextEditor: boolean,
): DropdownOption[] => {
  const options = survey.questionSetAttributes.filter(isActiveQuestion).map((qSet, index) => {
    let option: DropdownOption = {
      label: '',
      value: '',
      id: optionId,
    };

    if (qSet.questions && qSet.questions[0]) {
      const isNps = qSet.questions[0].type === SurveyQuestionType.Nps;
      const labelText = isNps
        ? `Question ${index + 1} - ”How likely is it that you would recommend ${qSet.questions[0].value} to a friend or colleague?”`
        : `Question ${index + 1} - ”${qSet.questions[0].value}”`;
      option = {
        label: labelText,
        value: qSet.logicalId,
        disabled: num >= index,
        id: optionId,
        hasRichTextLabel: hasRichTextEditor,
      };
    }

    return option;
  });

  return [...options, { ...IS_END_OPTION, id: optionId }];
};

// get initial skip logic statements from questionSet
export const defineInitialStmtsV12 = (
  hasAnswerChoices: boolean,
  questionSet: ISurveyQuestionSet,
  survey: MultiAppsSurvey,
): SkipLogicStatement[] => {
  const invokes: ActionAttribute[] = questionSet.invokes || [];
  if (!invokes || isEmpty(invokes.filter(isActiveNotDefaultInvoke))) {
    return [{ ...DEFAUL_STATEMENT, key: ObjectID().toHexString(), label: '' }];
  }

  const stmts = getSkipLogicStmtsFromQSetV12(hasAnswerChoices, questionSet);
  if (isEmpty(stmts)) {
    return [{ ...DEFAUL_STATEMENT, key: ObjectID().toHexString(), label: '' }];
  }

  return stmts.map((stmt) => ({
    ...stmt,
    questionSetOption: {
      ...stmt.questionSetOption,
      label: getQSetNameByIdV12(stmt.questionSetOption.value, survey.questionSetAttributes),
    },
  }));
};

// get initial question chosen answers from skip logic statement
export const defineInitialChosenAnswers = (skipStmts: SkipLogicStatement[], isRangeOrNps = false): string[] => {
  let res = skipStmts.map((s) => s.answerOption.value || '');
  if (isRangeOrNps) {
    res = skipStmts.map((s) =>
      Number.isNaN(s.answerOption.value) ? s.answerOption.value : Number(s.answerOption.value),
    ) as string[];
  }
  return res;
};

// get initial question from skip logic statement
export const defineInitialChosenQuestions = (skipStmts: SkipLogicStatement[]): string[] =>
  skipStmts.map((s) => s.questionSetOption.value || '');

export const getChosenQuestionsIdsV12 = (survey?: MultiAppsSurvey): Map<string, string> => {
  const choseQuestionsMap = new Map();

  if (!survey) {
    return choseQuestionsMap;
  }

  survey.questionSetAttributes.filter(isActiveQuestion).forEach((qSet) => {
    if (!qSet.invokes || isEmpty(qSet.invokes.filter(isNotDefaultInvoke))) {
      return;
    }

    const invokes = qSet.invokes.filter(isNotDefaultInvoke);
    invokes.forEach((inv) => {
      if (inv._destroy) {
        return;
      }

      choseQuestionsMap.set(inv.nextLogicalId, qSet.logicalId);
    });
  });

  return choseQuestionsMap;
};

export const AddSkipLogicButton: React.FC<{
  onClick: () => void;
  className?: string;
  disabled: boolean;
}> = ({ onClick, className, disabled }) => {
  return (
    <Button onClick={onClick} className={className} disabled={disabled}>
      Add Skip Logic
    </Button>
  );
};

/*
===================================================================================================================================
====== DATA STRUCTURE OF SKIP LOGIC INSTRUCTIONS or question_set_criteria FIELD IN QUESTION SET FOR NPS/RANGE QUESTION TYPES ======
===================================================================================================================================
--- 1. for COMPARATOR: 'IS' ==> $gte is always first option ---
--- 2. for COMPARATOR: 'IS_NOT' ==> $lte is always first option ---
[
  QUESTION_TYPE: 'NPS', COMPARATOR: 'IS',  NPS_GROUP: 'Detractors' |0 - 6|
  {
    "$or": [
      {
        "$and": [
          {
            "interactions/61addbecdb752c00010001fd/current_answer/value": {
              "$gte": 0
            }
          },
          {
            "interactions/61addbecdb752c00010001fd/current_answer/value": {
              "$lte": 6
            }
          }
        ]
      }
    ]
  },
  {
    QUESTION_TYPE: 'NPS', COMPARATOR: 'IS',  NPS_GROUP: 'Passives' |7 - 8|
    "$or": [
      {
        "$and": [
          {
            "interactions/61addbecdb752c00010001fd/current_answer/value": {
              "$gte": 7
            }
          },
          {
            "interactions/61addbecdb752c00010001fd/current_answer/value": {
              "$lte": 8
            }
          }
        ]
      }
    ]
  },
  {
    QUESTION_TYPE: 'NPS', COMPARATOR: 'IS',  NPS_GROUP: 'Promoters' |9 - 10|
    "$or": [
      {
        "$and": [
          {
            "interactions/61addbecdb752c00010001fd/current_answer/value": {
              "$gte": 9
            }
          },
          {
            "interactions/61addbecdb752c00010001fd/current_answer/value": {
              "$lte": 10
            }
          }
        ]
      }
    ]
  },

  {
    QUESTION_TYPE: 'NPS', COMPARATOR: 'IS_NOT',  NPS_GROUP: 'Detractors' |10 - 7|
    "$and": [
      {
        "$or": [
          {
            "interactions/61addbecdb752c00010001fd/current_answer/value": {
              "$lte": 10
            }
          },
          {
            "interactions/61addbecdb752c00010001fd/current_answer/value": {
              "$gte": 7
            }
          }
        ]
      }
    ]
  },
  {
    QUESTION_TYPE: 'NPS', COMPARATOR: 'IS_NOT',  NPS_GROUP: 'Passives' |1 - 6| && |7 - 10|
    "$or": [
      {
        "$and": [
          {
            "interactions/61addbecdb752c00010001fd/current_answer/value": {
              "$lte": 6
            }
          },
          {
            "interactions/61addbecdb752c00010001fd/current_answer/value": {
              "$gte": 9
            }
          }
        ]
      }
    ]
  },
  {
    QUESTION_TYPE: 'NPS', COMPARATOR: 'IS_NOT',  NPS_GROUP: 'Promoters' |0 - 8|
    "$and": [
      {
        "$and": [
          {
            "interactions/61addbecdb752c00010001fd/current_answer/value": {
              "lte": 8
            }
          },
          {
            "interactions/61addbecdb752c00010001fd/current_answer/value": {
              "$gte": 0
            }
          }
        ]
      }
    ]
  },

  {
    QUESTION_TYPE: 'NPS / RANGE', COMPARATOR: 'IS_BETWEEN', VALUES |5 - 7|
    "$or": [
      {
        "$and": [
          {
            "interactions/61addbecdb752c00010001fd/current_answer/value": {
              "$gt": "value - 1: 4"
            }
          },
          {
            "interactions/61addbecdb752c00010001fd/current_answer/value": {
              "$lt": "value + 1: 8"
            }
          }
        ]
      }
    ]
  },

  {
    QUESTION_TYPE: 'NPS / RANGE', COMPARATOR: 'IS_NOT_BETWEEN', VALUES |5 - 7|
    "$or": [
      {
        "$and": [
          {
            "interactions/61addbecdb752c00010001fd/current_answer/value": {
              "$lt": "value: 5"
            }
          },
          {
            "interactions/61addbecdb752c00010001fd/current_answer/value": {
              "$gt": "value: 7"
            }
          }
        ]
      }
    ]
  }
]
*/
