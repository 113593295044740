/**
 * GET  /insights/apps/${appId}/insights/aggs
 * GET  /insights/apps/${appId}/insights/data
 * GET  /insights/apps/${appId}/insights/data/tags/search
 * GET  /insights/apps/${appId}/insights/export/csv
 * GET  /insights/apps/${appId}/user/${userId}/significant-phrases
 * PUT  /insights/apps/${appId}/significant-phrases/${action}
 *
 * POST /insights/tag
 * PUT  /insights/tag/rename
 * PUT  /insights/tag/remove
 *
 * POST /insights/tags/search/name-and-count
 * GET  /insights/tags/${orgId}/most-used
 * GET  /insights/tags/${appId}
 * GET  /insights/tags/conversation/${conversationId}
 * POST /insights/tags/conversation
 *
 */

import { CustomDataEntity } from 'src/reducers/apps.types';
import { SURVEYS_PAGINATION_LIMIT } from 'src/services/survey-service';
import { localDateString } from 'src/utils/date_format';

//
const insightsAppUrl = (path: string) => `/insights/apps${path}`;
const insightsTagUrl = (path: string) => `/insights/tag${path}`;
const insightsTagsUrl = (path: string) => `/insights/tags${path}`;

export const WEBSOCKET = {
  INSIGHTS_EXPORT: `${process.env.ATRIUM_WEBSOCKET}/ws/insights/export`,
};

export const API = {
  APP: (appId: string) => `/apps/${appId}`,
  APP_STYLES: (appId: string) => `/apps/${appId}/styles`, // PUT
  APP_LEGACY: (appId: string) => `/apps/${appId}/legacy`,

  INSIGHTS: {
    APP: (appId: string) => ({
      AGGS: insightsAppUrl(`/${appId}/insights/aggs`),
      DATA: insightsAppUrl(`/${appId}/insights/data`),
      EXPORT: insightsAppUrl(`/${appId}/insights/export`), // POST
      EXPORT_CSV: insightsAppUrl(`/${appId}/insights/export/csv`), // GET
      SIGN_PHRASES_BY_USER: (userId: string) => insightsAppUrl(`/${appId}/user/${userId}/significant-phrases`),
      SIGN_PHRASES_ACTION: (action: string) => insightsAppUrl(`/${appId}/significant-phrases/${action}`),
    }),
    SAVED_INSIGHTS: '/insights/saved-insights', // POST, PUT
    SAVED_INSIGHT_BY_ID: (insightId: string) => `/insights/saved-insights/${insightId}`, // DELETE
    SAVED_INSIGHTS_BY_APP: (appId: string) => `/insights/saved-insights/apps/${appId}`, // GET
    EXPORT_FILE_URL: insightsAppUrl('/export/get-file-url'), // POST
    EXPORT_GET_FILE_URL: insightsAppUrl('/export/get-file'), // POST
    UPDATE_SENTIMENT: '/elastic-interaction/sentiment', //  PUT
  },

  TAG: {
    GET: insightsTagUrl(''),
    CREATE: insightsTagUrl(''),
    RENAME: insightsTagUrl('/rename'),
    REMOVE: insightsTagUrl('/remove'),
    RENAME_GLOBAL: insightsTagUrl('/rename-global'),
    REMOVE_GLOBAL: insightsTagUrl('/remove-global'),
  },

  TAGS: {
    ALL: insightsTagsUrl('/search/name-and-count'), // POST
    MOST_USED: (appId: string) => insightsTagsUrl(`/${appId}/most-used`),
    APP: (appId: string) => insightsTagsUrl(`/app/${appId}`),
    CONVERSATION: (conversationId: string) => insightsTagsUrl(`/conversation/${conversationId}`), // GET
    CONVERSATIONS: insightsTagsUrl('/conversations'), // POST
    AGGS: (appId: string) => insightsAppUrl(`/${appId}/insights/tags/aggs`), // GET
  },

  SURVEY: {
    ALL: (appId: string, page: number, isActive: boolean, apiVersion = '14') =>
      `/apps/${appId}/surveys?api_version=${apiVersion}&limit=${SURVEYS_PAGINATION_LIMIT}&page=${page}&active=${isActive}`, // GET
    GET: (appId: string, surveyId: string, apiVersion = '14') =>
      `/apps/${appId}/surveys/${surveyId}?api_version=${apiVersion}`, // GET
    CREATE: (appId: string, apiVersion = '12') => `/apps/${appId}/surveys?api_version=${apiVersion}`, // POST
    UPDATE: (appId: string, surveyId: string, apiVersion = '12') =>
      `/apps/${appId}/surveys/${surveyId}?api_version=${apiVersion}`, // PUT
    STATS: (appId: string, surveyId: string) => `/journey-reporting/apps/${appId}/surveys/${surveyId}/stats`, // GET
    TEXT_ANSWERS: (appId: string, surveyId: string, questionId: string) =>
      `/journey-reporting/apps/${appId}/surveys/${surveyId}/questions/${questionId}/text_answers`, // GET
    CLONE: (appId: string, surveyId: string) => `/apps/${appId}/surveys/${surveyId}/clone_to_app`, // POST
  },

  SURVEY_MULTI_APPS: {
    ALL: (orgId: string, page: number, isActive: boolean, apiVersion = '14') =>
      `/orgs/${orgId}/surveys?api_version=${apiVersion}&limit=${SURVEYS_PAGINATION_LIMIT}&page=${page}&active=${isActive}`, // GET
    GET: (orgId: string, surveyId: string, apiVersion = '14') =>
      `/orgs/${orgId}/survey/${surveyId}?api_version=${apiVersion}`, // GET
    CREATE: (orgId: string, apiVersion = '12') => `/orgs/${orgId}/survey?api_version=${apiVersion}`, // POST
    UPDATE: (orgId: string, surveyId: string, apiVersion = '12') =>
      `/orgs/${orgId}/survey/${surveyId}?api_version=${apiVersion}`, // PUT
    STATS: (orgId: string, surveyId: string, start: string, end: string) =>
      `/journey-reporting/orgs/${orgId}/unified_surveys/${surveyId}/stats?start_date=${start}&end_date=${end}`, // POST
    TEXT_ANSWERS: (orgId: string, surveyId: string, questionId: string, start: string, end: string) =>
      `/journey-reporting/orgs/${orgId}/unified_surveys/${surveyId}/questions/${questionId}/text_answers?start_date=${start}&end_date=${end}`, // POST
    CLONE: (orgId: string, surveyId: string) => `/orgs/${orgId}/survey/${surveyId}/clone`, // POST
    ACTIVATE: (appId: string, surveyId: string, apiVersion = '12') =>
      `/apps/${appId}/surveys/${surveyId}/activate?api_version=${apiVersion}`, // PUT
    DEACTIVATE: (appId: string, surveyId: string, apiVersion = '12') =>
      `/apps/${appId}/surveys/${surveyId}/deactivate?api_version=${apiVersion}`, // PUT
    IMT_ACTIVATE: (orgId: string, surveyId: string, apiVersion = '12') =>
      `/orgs/${orgId}/survey/${surveyId}/unified_activate?api_version=${apiVersion}`, // POST
    IMT_DEACTIVATE: (orgId: string, surveyId: string, apiVersion = '12') =>
      `/orgs/${orgId}/survey/${surveyId}/unified_deactivate?api_version=${apiVersion}`, // POST
    DELETE: (orgId: string, surveyId: string, apiVersion = '12') =>
      `/orgs/${orgId}/survey/${surveyId}?api_version=${apiVersion}`, // DELETE
  },

  PROMPTS: {
    ALL: (appId: string) => `/apps/${appId}/prompts`, // GET
    GET: (appId: string, noteId: string) => `/apps/${appId}/prompts/${noteId}`, // GET
    CREATE: (appId: string, apiVersion = '13') => `/apps/${appId}/prompts?api_version=${apiVersion}`, // POST
    UPDATE: (appId: string, noteId: string, apiVersion = '13') =>
      `/apps/${appId}/prompts/${noteId}?api_version=${apiVersion}`, // PUT
    DELETE: (appId: string, noteId: string) => `/apps/${appId}/prompts/${noteId}`, // DELETE
    CLONE: (appId: string, noteId: string) => `/apps/${appId}/prompts/${noteId}/clone`, // POST
  },

  PROMPTS_MULTI_APPS: {
    ALL: (orgId: string) => `/orgs/${orgId}/prompts`, // GET
    GET: (orgId: string, promptId: string) => `/orgs/${orgId}/prompt/${promptId}`, // GET
    CREATE: (orgId: string, apiVersion = '13') => `/orgs/${orgId}/prompt?api_version=${apiVersion}`, // POST
    UPDATE: (orgId: string, promptId: string, apiVersion = '13') =>
      `/orgs/${orgId}/prompt/${promptId}?api_version=${apiVersion}`, // PUT
    DELETE: (orgId: string, promptId: string) => `/orgs/${orgId}/prompt/${promptId}`, // DELETE
    CLONE: (orgId: string, promptId: string) => `/orgs/${orgId}/prompt/${promptId}/clone`, // POST
  },

  LOVE_DIALOG: {
    FLOWS: (appId: string) => `/apps/${appId}/rating_flows`, // GET
    SAVE: (appId: string, flowId: string) => `/apps/${appId}/rating_flows/${flowId}`, // PUT
  },

  RATING_PROMPT: {
    GET: (appId: string) => `/apps/${appId}/rating_prompt`, // GET
    SAVE: (appId: string) => `/apps/${appId}/rating_prompt`, // PUT
  },

  INITIATORS: {
    ALL: (appId: string) => `/apps/${appId}/initiators`, // GET
    GET: (appId: string, initiatorId: string) => `/apps/${appId}/initiators/${initiatorId}`, // GET
    UPDATE: (appId: string, initiatorId: string) => `/apps/${appId}/initiators/${initiatorId}`, // PUT
    DELETE: (appId: string, initiatorId: string) => `/apps/${appId}/initiators/${initiatorId}`, // DELETE
  },

  WEB_HOOK: {
    FS_GET: (appId: string) => `/fs-webhook/webhooks?app_id=${appId}`, // GET
    FS_CREATE: '/fs-webhook/webhook', // POST
    FS_UPDATE: (fsId: string) => `/fs-webhook/webhook/${fsId}`, // POST
    FS_DELETE: (fsId: string) => `/fs-webhook/webhook/${fsId}`, // DELETE
    FS_TEST: '/fs-webhook/webhook-test', // POST
  },

  MONGO_INTERACTION: {
    GET_APP: (appId: string) => `/schema-interaction/api/v1/private/apps/${appId}`, // GET
    GET_APPS: `/schema-interaction/api/v2/private/apps/ids`, // POST
    PUT_APP: (appId: string) => `/schema-interaction/api/v1/private/apps/${appId}`, // PUT
    DELETE_ATTACHMENT: '/schema-interaction/api/v1/private/remove-attachment', // POST
    UPD_UI_STATE: (userId: string) => `/schema-interaction/api/v1/private/user/${userId}`, // PUT
    GET_MESSAGE_CENTER_IDS_BY_APPS: `/schema-interaction/api/v2/private/apps/ids/get-message-center`, // POST
  },

  EVENT_METRICS: {
    SEND: '/event-metric', // POST
  },

  ATTRIBUTES_SERVICE_V2: {
    GET_SUGGESTED_ATTRIBUTES: (appId: string) => `/attributes-service-v2/apps/${appId}`, // GET
    GET_ATTRIBUTES: (appId: string, isPerson: boolean) =>
      `/attributes-service-v2/apps/${appId}/${isPerson ? 'person' : 'device'}/keys?limit=250&only_visible=false`, // GET
    UPD_ATTRIBUTES: (appId: string) => `/attributes-service-v2/apps/${appId}/keys`, // PUT
    GET_MULTI_APP_ATTRIBUTES_KEYS: (orgId: string, entity: CustomDataEntity) =>
      `/attributes-service-v2/orgs/${orgId}/${entity}/keys?limit=250&only_visible=false`, // POST
    GET_MULTI_APP_ATTRIBUTE_VALUES: (orgId: string, entity: CustomDataEntity, key: string) =>
      `/attributes-service-v2/apps/${orgId}/${entity}/keys/${key}?limit=250&only_visible=false`, // POST
  },

  FAN_SIGNALS: {
    GET_COUNTS: (appId: string, start: string, end: string) =>
      `/apps/${appId}/fan_signals/counts?start=${start}&end=${end}`, // GET
    GET_LOVE_SCORE: (appId: string, start: string, end: string) =>
      `/apps/${appId}/fan_signals/love_score?start=${start}&end=${end}`, // GET
  },

  EVENT_STATS: {
    GET_TARGETING_EVENTS: (appId: string, includeArchived: boolean, sortBy: string, sortDirection: string) =>
      `/apps/${appId}/events_targeting?include_archived=${includeArchived}&sort_by=${sortBy}&sort_direction=${sortDirection}`, // GET
    GET_PRIMARY_LD_EVENTS: (appId: string, start: string, end: string) =>
      `/apps/${appId}/event_stats?start_date=${start}&end_date=${end}&label[]=enjoyment_dialog.launch&label[]=enjoyment_dialog.yes&label[]=enjoyment_dialog.no&all_dates=true`, // GET
    GET_EVENTS_BY_INTERACTION_ID: (appId: string, intId: string, start: string, end: string, query: string) =>
      `/apps/${appId}/interaction/${intId}/stats?start_date=${start}&end_date=${end}${query}`, // GET
    GET_EVENTS: (appId: string, queryString: string) => `/apps/${appId}/events?${queryString}`, // GET
    GET_ALL_EVENTS: (appId: string) => `/apps/${appId}/events`, // GET
    CREATE_EVENT: (appId: string) => `/apps/${appId}/events`, // POST
    UPDATE_EVENT: (appId: string, label: string) => `/apps/${appId}/events/${encodeURIComponent(label)}`, // PUT
    DELETE_EVENT: (appId: string, label: string) => `/apps/${appId}/events/${encodeURIComponent(label)}`, // DELETE
    GET_EVENTS_TIMESERIES: (appId: string, labels: string[], start: number, end: number) => {
      let path = `/apps/${appId}/events-timeseries?start_date=${start}&end_date=${end}&all_dates=true`;
      labels.forEach((event) => {
        path += `&label[]=${encodeURIComponent(event)}`;
      });
      return path;
    },
    CREATE_MULTIPLE_APPS_EVENT: (orgId: string) => `/orgs/${orgId}/events`, // POST
    GET_MULTIPLE_APPS_TARGETING_EVENTS: (
      orgId: string,
      includeArchived: boolean,
      sortBy: string,
      sortDirection: string,
    ) =>
      `/orgs/${orgId}/events_targeting?include_archived=${includeArchived}&sort_by=${sortBy}&sort_direction=${sortDirection}`, // POST
  },

  APP_STORE: {
    GET_RATINGS: (store: string, storeAppId: string, start: number, end: number, isRegionUS: boolean, minKey: number) =>
      `/app-store/store/${store}/apps/${storeAppId}/ratings-histograms?start_date=${localDateString(
        start,
      )}&end_date=${localDateString(end)}${isRegionUS ? '&regions=US' : ''}&page_size=2000${
        minKey ? `&min_key=${minKey}` : ''
      }`, // GET
    GET_RATINGS_UNIQUE: (store: string, storeAppId: string, isRegionUS: boolean, minKey: number) =>
      `/app-store/store/${store}/apps/${storeAppId}/ratings-histograms/unique?page_size=2000${
        isRegionUS ? '&regions=US' : ''
      }${minKey ? `&min_key=${minKey}` : ''}`, // GET
  },

  DRUID: {
    GET_SKIP_LOGIC_REACH: (appId: string) => `/druid-http-service/api/v1/app/${appId}/reach/sdk/skip-logic`, // GET
    GET_REACH_BY_VERSION: (appId: string, version: string) =>
      `/druid-http-service/api/v1/app/${appId}/reach/sdk/${version}`, // GET
    GET_MULTIPLE_REACH_BY_VERSION: (orgId: string, version: string) =>
      `/druid-http-service/api/v1/org/${orgId}/reach/sdk/${version}`, // GET
    GET_MULTIPLE_LATEST_SDK_VERSION: (orgId: string) => `/druid-http-service/api/v1/org/${orgId}/reach/sdk/latest`, // GET
  },

  S3: {
    GET_PRESIGNED_URL: (appId: string, bucketName: string, fileKey: string, fileType: string) =>
      `/apps/${appId}/get-presigned-url?bucketName=${bucketName}&fileKey=${fileKey}&fileType=${fileType}`, // GET
    GET_PRESIGNED_URL_BY_ORG: (orgId: string, bucketName: string, fileKey: string, fileType: string) =>
      `/orgs/${orgId}/get-presigned-url?bucketName=${bucketName}&fileKey=${fileKey}&fileType=${fileType}`, // GET
  },

  UNIFIED_INTERACTIONS: {
    GET_TARGETING: (orgId: string, unifiedInteractionId: string) =>
      `/organization/${orgId}/interaction/${unifiedInteractionId}/targeting`,
  },

  DOWNLOADS: {
    GET_DOWNLOADS: (userId: string) => `/schema-interaction/api/v2/private/users/${userId}/unified-downloads`, // GET
    CREATE: (userId: string) => `/journey-reporting/users/${userId}/unified-downloads`, // POST
    GET_DOWNLOAD_URL: (userId: string, downloadId: string) =>
      `/journey-reporting/users/${userId}/unified-downloads/${downloadId}/download-file`, // GET
  },
};
