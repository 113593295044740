import React from 'react';
import Toggle from 'react-toggle';

import { bemPrefix } from 'src/utils';
import { GtmID } from 'src/types/google-tag-manager';
import 'react-toggle/style.css';
import './input-toggler.scss';

const bem = bemPrefix('toggle-container');
const noop = () => null;

export interface InputTogglerProps {
  title?: string;
  isChecked: boolean;
  onCheck: () => void;
  hasIcons?: boolean;
  className?: string;
  gtmId?: GtmID;
  disabled?: boolean;
}

export const InputToggler: React.FC<InputTogglerProps> = ({
  title = '',
  isChecked = false,
  onCheck = noop,
  hasIcons = false,
  className = '',
  disabled,
  gtmId,
}) => {
  return (
    <div className={`${bem()} ${className}`} gtm-id={gtmId}>
      <Toggle title={title} checked={isChecked} onChange={onCheck} icons={hasIcons} disabled={disabled} />
    </div>
  );
};
