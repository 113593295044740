import { ExtendedOrganizationApp } from 'src/components/molecules';
import { AppEventList } from 'src/reducers/events/events.types';
import { OrganizationApp } from 'src/reducers/organizations';
import { Platform } from 'src/types/core';
import defaultRules from '../data/rules';
import {
  CriteriaAnyRule,
  CriteriaRuleGroup,
  CriteriaChildRule,
  CriteriaParentRule,
  CriteriaUnifiedTargeting,
  CriteriaUnifiedAttributes,
} from '../types';

const BUILTIN_INTERACTIONS: Record<string, Set<Platform>> = {
  // Any Survey
  'com.apptentive#Survey#launch': new Set([Platform.Web, Platform.iOS, Platform.Android]),

  // Any Prompt
  'com.apptentive#TextModal#launch': new Set([Platform.Web, Platform.iOS, Platform.Android]),

  // Love Dialog Displayed
  'com.apptentive#EnjoymentDialog#launch': new Set([Platform.Web, Platform.iOS, Platform.Android]),

  // Selected "Love"
  'com.apptentive#EnjoymentDialog#yes': new Set([Platform.Web, Platform.iOS, Platform.Android]),

  //  Selected "Don't Love"
  'com.apptentive#EnjoymentDialog#no': new Set([Platform.Web, Platform.iOS, Platform.Android]),

  // Apptentive Rating Dialog
  'com.apptentive#RatingDialog#launch': new Set([Platform.iOS, Platform.Android]),

  // Apple Rating Dialog
  'com.apptentive#AppleRatingDialog#shown': new Set([Platform.iOS]),

  // Google Rating Dialog
  'com.apptentive#InAppRatingDialog#shown': new Set([Platform.Android]),
};

export type AppRuleIncompatibilityInfo = {
  usesArchivedEvent?: boolean;
};

export const COUNT_IN_INSTALLED_VERSION_SUFFIXES = new Set([
  'invokes/cf_bundle_version',
  'invokes/cf_bundle_short_version_string',
  'invokes/version_code',
  'invokes/version',
  'invokes/version_name',
]);

function usesCountInInstalledVersion(details: CriteriaParentRule['details']): boolean {
  return details.some(({ suffix }) => suffix && COUNT_IN_INSTALLED_VERSION_SUFFIXES.has(suffix));
}

export const isAppCompatibleWithRule = ({
  app,
  rule,
  multipleEvents,
  unifiedTargeting,
  unifiedAttributes,
}: {
  app: OrganizationApp;
  rule: CriteriaAnyRule;
  multipleEvents: AppEventList[];
  unifiedTargeting: CriteriaUnifiedTargeting;
  unifiedAttributes: CriteriaUnifiedAttributes;
}): [boolean, AppRuleIncompatibilityInfo] => {
  // Check for explicit platform rules
  if (rule.group === CriteriaRuleGroup.APPLICATION && rule.key === 'platform') {
    const { comparator, value } = rule as CriteriaChildRule;
    return [comparator === '$ne' ? app.platform !== value : app.platform === value, {}];
  }

  switch (rule.group) {
    case CriteriaRuleGroup.BULK_UPLOAD: {
      const { details } = rule as CriteriaParentRule;

      if (details[0]) {
        return isAppCompatibleWithRule({ app, rule: details[0], multipleEvents, unifiedTargeting, unifiedAttributes });
      }

      return [true, {}];
    }

    case CriteriaRuleGroup.CODE_POINT: {
      const { details } = rule as CriteriaParentRule;

      let eventExistsResult = true;
      if (app.platform === Platform.Web && usesCountInInstalledVersion(details)) eventExistsResult = false;

      const appEvents = multipleEvents.find(({ app_id }) => app_id === app.id)?.items || [];

      const event = appEvents.find(({ label }) => label === rule.key);

      if (event) {
        if (event.archived) {
          return [eventExistsResult, { usesArchivedEvent: true }];
        }

        return [eventExistsResult, {}];
      }

      return [false, {}];
    }

    case CriteriaRuleGroup.DEVICE:
    case CriteriaRuleGroup.PERSON: {
      if (rule.key.includes('custom_data/')) {
        const groupAttributes = unifiedAttributes[rule.group];

        const attribute = groupAttributes?.find(({ key }) => key === rule.key.replace('custom_data/', 'custom_data.'));

        return [attribute != null && attribute.app_ids.includes(app.id), {}];
      }

      break;
    }

    case CriteriaRuleGroup.INTERACTIONS: {
      const { details } = rule as CriteriaParentRule;
      if (app.platform === Platform.Web && usesCountInInstalledVersion(details)) return [false, {}];

      const builtinInteractionPlatforms = BUILTIN_INTERACTIONS[rule.key];

      if (builtinInteractionPlatforms) {
        return [builtinInteractionPlatforms.has(app.platform), {}];
      }

      const app_ids =
        unifiedTargeting.interactions.find(({ unified_interaction_id: id }) => id === rule.key)?.app_ids || [];

      return [app_ids.includes(app.id), {}];
    }

    case CriteriaRuleGroup.IRT: {
      const { key, details } = rule as CriteriaParentRule;
      const app_ids = unifiedTargeting.interactions.find(({ unified_interaction_id: id }) => id === key)?.app_ids || [];

      if (!app_ids.includes(app.id)) return [false, {}];
      if (!details[0]) return [false, {}];

      return [unifiedTargeting.interaction_responses.some(({ key }) => key === details[0].target), {}];
    }

    default:
  }

  // Check for other kinds of rules
  const { group, key } = rule;
  const ruleKindValue = `${group}/${key}`;

  const ruleKind = defaultRules.find(
    ({ value }) =>
      value === ruleKindValue || value === group || (group === CriteriaRuleGroup.FS_STATE && value === key),
  );

  if (ruleKind && ruleKind.platform) {
    return [ruleKind.platform.includes(app.platform), {}];
  }

  return [true, {}];
};

export const compatibleAppsForRules = ({
  apps,
  rules,
  multipleEvents,
  unifiedTargeting,
  unifiedAttributes,
}: {
  apps: OrganizationApp[];
  rules: CriteriaAnyRule[];
  multipleEvents: AppEventList[];
  unifiedTargeting: CriteriaUnifiedTargeting;
  unifiedAttributes: CriteriaUnifiedAttributes;
}): Set<ExtendedOrganizationApp> => {
  return new Set(
    apps
      .map((app): ExtendedOrganizationApp | null => {
        let hasArchivedEvent = false;

        if (
          !rules.every((rule) => {
            const [result, { usesArchivedEvent }] = isAppCompatibleWithRule({
              app,
              rule,
              multipleEvents,
              unifiedTargeting,
              unifiedAttributes,
            });

            if (usesArchivedEvent) hasArchivedEvent = true;

            return result;
          })
        ) {
          return null;
        }

        return { ...app, hasArchivedEvent };
      })
      .filter((a) => !!a) as ExtendedOrganizationApp[],
  );
};
