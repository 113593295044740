import { createSelector } from 'reselect';
import { indexBy, prop } from 'ramda';
import { ApplicationIntegratedFeature, IApplication, Platform, ReachByAppAndVersion, SdkVersion } from 'src/types/core';
import { State } from 'src/reducers';
import { AppMember, AttributeField } from 'src/reducers/apps.types';
import { Styles } from 'src/interactions/components/interaction-styling/interaction-styles.defaults';
import { getCurrentUserId } from './user';

export const getCurrentAppId = (state: State) => {
  try {
    const pieces = window.location.pathname.split('/');
    const urlId = pieces[2] || '';
    if (pieces[1] !== 'apps' || urlId === 'new' || urlId === 'current' || !urlId) {
      // NOTE: Not all routes will know their current app ID, so we must trust the store.
      return state.currentApp || '';
    }
    return urlId;
  } catch (_error: any) {
    return '';
  }
};

// TODO: This is a shim until we can safely replace getCurrentAppId to not rely on route
export const getCurrentRealAppId = (state: State) => state.currentApp || '';
export const getApps = (state: State) => state.apps || {};

const getAppsListFromApps = (apps: State['apps']) =>
  Object.values(apps).filter((value): value is IApplication => typeof value === 'object' && value.id != null);

export const getAppsList = createSelector(getApps, (apps) => getAppsListFromApps(apps));

export const getAppReaches = createSelector(
  getAppsList,
  (apps): ReachByAppAndVersion =>
    Object.fromEntries(apps.map((app) => [app.id, app.reachBySdkVersion]).filter(([, reach]) => reach != null)),
);

export const getMessageCenterIdsByApps = createSelector(
  getAppsList,
  (apps): Record<string, string> =>
    Object.fromEntries(
      apps.map((app) => [app.id, app.messageCenterId]).filter(([, messageCenterId]) => messageCenterId != null),
    ),
);

export const getMessageCenterIdsAnyAppIsLoading = createSelector(getAppsList, (apps): boolean =>
  apps.some((app) => !!app.isMessageCenterIdLoading),
);

export const getAppsCustomDataIsLoading = createSelector(getAppsList, (apps) =>
  Object.fromEntries(apps.map((app) => [app.id, !!app.isAppCustomDataLoading])),
);

export const getAppsCustomData = createSelector(getApps, (apps) =>
  Object.fromEntries(
    getAppsListFromApps(apps)
      .map((app) => [app.id, app.appCustomData])
      .filter(([, customData]) => customData),
  ),
);

export const getCurrentApp = createSelector(getCurrentAppId, getApps, (currentAppId, apps) => apps[currentAppId] || {});
export const getPendingUpdateCurrentApp = createSelector(getApps, (apps) => apps.pendingUpdateCurrentApp || false);
export const getAppLoading = createSelector(getCurrentApp, (app) => app.loading || false);
export const getCurrentAppMembers = createSelector(getCurrentApp, (app) => app.appMembers || []);
export const getCurrentAddAppMemberFailureErrorMessage = createSelector(
  getCurrentApp,
  (app) => app.addAppMemberFailureErrorMessage || '',
);
export const getCurrentAppMembership = createSelector(
  getCurrentUserId,
  getCurrentAppMembers,
  (currentUser, appMembers) => appMembers.find((member) => member.user_id === currentUser) || ({} as AppMember),
);
export const getCurrentAppMembershipId = createSelector(getCurrentAppMembership, (member) => member.id || '');
export const getCurrentAppMembershipRole = createSelector(getCurrentAppMembership, (member) => member.role || '');

export const isCurrentAppUserMemberAdmin = createSelector(getCurrentAppMembershipRole, (role) => role === 'admin');

export const getCurrentAppIcon = createSelector(getCurrentApp, (app) => app.iconUrl || '');
export const getCurrentAppPlatform = createSelector(getCurrentApp, (app) => app.platform || '');

export const getCurrentAppMessageCenterId = createSelector(getCurrentApp, (app) => app.messageCenterId || undefined);

// Obsolete
export const getCurrentAppWithObsoleteSDK = createSelector(
  getCurrentApp,
  (app) => app.client_support && app.client_support.message_center_v2 !== 'all',
);

const platformToStore: Record<string, string> = {
  android: 'android',
  ios: 'itunes',
};
export const getCurrentAppStore = createSelector(
  getCurrentAppPlatform,
  (platform) => platformToStore[platform.toLowerCase()] || '',
);
export const getCurrentAppTitle = createSelector(getCurrentApp, (app) => app.title || '');
export const getCurrentAppHideBranding = createSelector(getCurrentApp, (app) => app.hideBranding || false);
export const getIntlAppStorePref = createSelector(getCurrentApp, (app) => app.intlAppStorePref || false);
export const getWentLiveOn = createSelector(getCurrentApp, (app) => app.wentLiveOn || '2017-05-01');
export const getCurrentOrgId = createSelector(getCurrentApp, (app) => app.organization || '');

// Translations
export const getCurrentAppTranslations = createSelector(getCurrentApp, (app) => app.translations || {});
export const getCurrentAppDefaultLocale = createSelector(getCurrentApp, (app) => app.defaultLocale || 'en');
export const getCurrentAppLocalePolicy = createSelector(getCurrentApp, (app) => app.localePolicy || 'fallback');
export const getCurrentAppInteractionReminderEmails = createSelector(
  getCurrentApp,
  (app) => app.interactionReminderEmails || [],
);
export const getCurrentAppTranslationLoading = createSelector(getCurrentApp, (app) => app.translationsLoading || false);
export const getCurrentAppTranslationError = createSelector(getCurrentApp, (app) => app.translationsError || false);
export const getCurrentAppTranslationErrorMessage = createSelector(
  getCurrentApp,
  (app) => app.translationsErrorMessage || '',
);
export const getCurrentAppTranslationSaving = createSelector(getCurrentApp, (app) => app.translationsSaving || false);
export const getCurrentAppTranslationSavingError = createSelector(
  getCurrentApp,
  (app) => app.translationsSavingError || false,
);
export const getCurrentAppTranslationSavingErrorMessage = createSelector(
  getCurrentApp,
  (app) => app.translationsSavingErrorMessage || '',
);
export const getCurrentAppTranslationStatus = createSelector(getCurrentApp, (app) => app.translationsStatus || '');

// App Store
export const getStoreApps = (state: State & Record<string, any>) => state.storeApps || [];
export const getCurrentStoreAppId = createSelector(getCurrentApp, (app) => app.storeAppId || '');
export const getCurrentStoreApp = createSelector(
  getCurrentStoreAppId,
  getStoreApps,
  (currentStoreAppId, storeApps) => storeApps[currentStoreAppId] || {},
);
export const getCurrentStoreAppVersions = createSelector(
  getCurrentStoreApp,
  getCurrentAppPlatform,
  (currentStoreApp, platform) => {
    if (platform !== Platform.Web) {
      return currentStoreApp.appVersions || [];
    }
    return [];
  },
);

// Events
export const getAppEvents = createSelector(getCurrentApp, (app) => app.events || {});

/**
 * EventItem[]
 */
export const getCurrentAppAllEvents = createSelector(getCurrentApp, (app) =>
  app.allEvents ? app.allEvents.items || [] : [],
);

export const getAllAppsIdForCurrentOrd = createSelector(getCurrentOrgId, (apps) => apps || []);

export const getCurrentAppAllEventsIsBootstrapped = createSelector(getCurrentApp, (app) =>
  app.allEvents ? !!app.allEvents.bootstrapped : false,
);

export const getCurrentAppAllEventsIsLoading = createSelector(getCurrentApp, (app) =>
  app.allEvents ? !!app.allEvents.loading : false,
);

export const getCurrentAppUnseenEvents = createSelector(getCurrentApp, (app) => app.unseenEvents || []);
export const getCurrentAppArchivedEvents = createSelector(getCurrentApp, (app) => app.archivedEvents || []);
export const getCurrentAppEventsError = createSelector(getAppEvents, (events) => events.error || false);
export const getCurrentAppEventsLoading = createSelector(getAppEvents, (events) => events.loading || false);
export const getAppEventTimeseries = createSelector(getAppEvents, (events) => events.timeseries || {});
export const getAppEventTimeseriesData = createSelector(
  getAppEventTimeseries,
  (appEventTimeseries) => appEventTimeseries.data || [],
);

export const getCurrentAppEventsAggregate = createSelector(
  getAppEvents,
  (events) =>
    events.aggregate || {
      ends_with: '',
      has_more: false,
      indicies: [],
      items: [],
      selected: [],
      total: 0,
    },
);

export const getSelectedTimeseriesEvents = createSelector(
  getCurrentAppEventsAggregate,
  (appEventsAggregate) => appEventsAggregate.selected || [],
);

// Styles
/**
 * @return {*} styles: Record<string, string>
 */
export const getCurrentAppStyles = createSelector(getCurrentApp, ({ styles = [] }) =>
  styles.reduce<typeof Styles>((acc, item) => ({ ...acc, [item.name]: item.value }), {} as typeof Styles),
);

// Integrated Features
export const getCurrentAppFeatures = createSelector(
  getCurrentApp,
  (app) => app.integratedFeatures || ({} as Record<ApplicationIntegratedFeature, boolean>),
);
export const integratedRatingFlow = createSelector(getCurrentAppFeatures, (features) => features.rating_flow || false);
export const integratedSurvey = createSelector(getCurrentAppFeatures, (features) => features.survey || false);
export const integratedMessageCenter = createSelector(
  getCurrentAppFeatures,
  (features) => features.message_center || false,
);
export const integratedTextModal = createSelector(getCurrentAppFeatures, (features) => features.text_modal || false);

// Integrations
export const getIntegrationsLoading = createSelector(getCurrentApp, (app) => app.integrationsLoading);
export const getIntegrations = createSelector(getCurrentApp, (app) => app.integrations || {});
export const getIntegrationsSaving = createSelector(getCurrentApp, (app) => app.integrationsSaving || false);
export const getIntegrationsSavingError = createSelector(getCurrentApp, (app) => app.integrationsSavingError || false);
export const getCurrentAppIntegrationsSavingErrorMessage = createSelector(
  getCurrentApp,
  (app) => app.integrationsSavingErrorMessage || '',
);

// Attribute Choices
export const getAttributeChoices = createSelector(getCurrentApp, (app) => app.attributeChoices || {});
export const getAttributeChoicesError = createSelector(getCurrentApp, (app) => app.attributeChoicesError || false);
export const getAttributeChoicesLoading = createSelector(getCurrentApp, (app) => app.attributeChoicesLoading || false);

export const getAttributeChoicesPersonFields = createSelector(getCurrentApp, (app) => {
  return app.attributeChoices && app.attributeChoices.person ? app.attributeChoices.person.fields : [];
});

export const isAttributeChoicesPersonFieldsBootstrapped = createSelector(getCurrentApp, (app) =>
  app.attributeChoicesBootstrapped ? !!app.attributeChoicesBootstrapped.person : false,
);

export const getCustomDataAttributeOptions = createSelector(getAttributeChoicesPersonFields, (attrs) =>
  attrs.concat({
    key: 'conversation_id',
    name: 'conversation_id',
    type: 'string',
  } as AttributeField),
);

// Targeting
export const getTargeting = createSelector(getCurrentApp, (app) => app.targeting || {});
export const getTargetingError = createSelector(getCurrentApp, (app) => app.targetingError || false);
export const getTargetingLoading = createSelector(getCurrentApp, (app) => app.targetingLoading || false);

// Quick Responses
export const getCurrentAppQuickResponses = createSelector(getCurrentApp, (app) => app.quickResponses || []);
const getIndexedQuickResponses = createSelector(getCurrentAppQuickResponses, (quickResponses) =>
  indexBy(prop('id'), quickResponses),
);
export const getCurrentAppQuickResponse = (responseId: string) =>
  createSelector(getIndexedQuickResponses, (quickResponses) => quickResponses[responseId]);

// Settings: Global Settings
export const getCurrentAppSupportEmail = createSelector(getCurrentApp, (app) => app.supportDisplayEmail || '');
export const getCurrentAppSupportName = createSelector(getCurrentApp, (app) => app.supportDisplayName || '');
export const getCurrentAppSupportImage = createSelector(getCurrentApp, (app) => app.supportImageUrl || '');
export const getCurrentAppStoreIcon = createSelector(getCurrentApp, (app) => app.appStoreIcon || '');
export const getCurrentAppDisplayName = createSelector(getCurrentApp, (app) => app.displayName || '');

// Settings: API Page
export const getCurrentAppWebsdkDebugFlag = createSelector(getCurrentApp, (app) => app.websdkDebugFlag || false);
export const getCurrentAppAuthenticationKey = createSelector(getCurrentApp, (app) => app.authenticationKey || '');
export const getCurrentAppAuthenticationSignature = createSelector(
  getCurrentApp,
  (app) => app.authenticationSignature || '',
);
export const getCurrentAppJwtSecret = createSelector(getCurrentApp, (app) => app.jwtSecret || '');
export const getCurrentAppOauthToken = createSelector(getCurrentApp, (app) => app.oauthToken || '');
export const getCurrentAppDebugJwtToken = createSelector(getCurrentApp, (app) => app.debugJwtToken || '');

// Delete App
export const getDeleteAppPending = createSelector(getCurrentApp, (app) => app.deleteAppPending || false);
export const getDeleteAppFailure = createSelector(getCurrentApp, (app) => app.deleteAppFailure || false);
export const getDeleteAppFailureMessage = createSelector(getCurrentApp, (app) => app.deleteAppFailureMessage || '');
export const getDeleteAppSuccess = createSelector(getCurrentApp, (app) => app.deleteAppSuccess || false);

// Update App
export const getCurrentAppUpdatePending = createSelector(getCurrentApp, (app) => app.updateAppPending || false);
export const getCurrentAppUpdateFailure = createSelector(getCurrentApp, (app) => app.updateAppFailure || false);
export const getCurrentAppUpdateFailureMessage = createSelector(
  getCurrentApp,
  (app) => app.updateAppFailureMessage || '',
);
export const getCurrentAppUpdateSuccess = createSelector(getCurrentApp, (app) => app.updateAppSuccess || false);

// SelectedCIDKeys
export const getCurrentAppSelectedCIDKeys = createSelector(getCurrentApp, (app) => app.selected_cid_keys || []);

export const getCurrentAppPrimaryCIDKey = createSelector(getCurrentAppSelectedCIDKeys, (keys) =>
  keys.find((item) => item.primary),
);

export const getCurrentAppAttributes = createSelector(getCurrentApp, (app) => app.appAttributes || null);

export const getCurrentAppAttributesWithConvId = createSelector(getCurrentApp, (app) =>
  app.appAttributes && app.appAttributes.keys
    ? {
        ...app.appAttributes,
        keys: [...app.appAttributes.keys, 'conversation_id'],
      }
    : {
        suggested_keys: [],
        keys: ['conversation_id'],
      },
);

export const getCurrentAppLoveScore = createSelector(
  getCurrentApp,
  (app) => app.love_score || { score: 0, categoryScore: 0, categoryName: '' },
);

export const getCurrentAppSLReach = createSelector(
  getCurrentApp,
  (app) => app.reachBySdkVersion?.[SdkVersion.SupportsSkipLogic] || 0,
);

export const getCurrentAppRCReach = createSelector(
  getCurrentApp,
  (app) => app.reachBySdkVersion?.[SdkVersion.SupportsRichContent] || 0,
);

export const getCurrentAppRichTextReach = createSelector(
  getCurrentApp,
  (app) => app.reachBySdkVersion?.[SdkVersion.SupportsRichText] || 0,
);

export const getCurrentAppInitiatorReach = createSelector(
  getCurrentApp,
  (app) => app.reachBySdkVersion?.[SdkVersion.SupportsInitiators] || 0,
);

export const getActivePersonCustomData = createSelector(getCurrentApp, (app) => app.personActiveCustomData || {});

export const getArchievedPersonCustomData = createSelector(getCurrentApp, (app) => app.personArchievedCustomData || {});

export const getActiveDeviceCustomData = createSelector(getCurrentApp, (app) => app.deviceActiveCustomData || {});

export const getArchievedDeviceCustomData = createSelector(getCurrentApp, (app) => app.deviceArchievedCustomData || {});

export const getCustomDataIsLoading = createSelector(getCurrentApp, (app) => app.isCustomDataPending || false);

export const getPersonCustomDataLoadingKeys = createSelector(
  getCurrentApp,
  (app) => app.personCustomDataLoadingKeys || [],
);

export const getDeviceCustomDataLoadingKeys = createSelector(
  getCurrentApp,
  (app) => app.deviceCustomDataLoadingKeys || [],
);
