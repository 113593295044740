import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { DownloadService } from 'src/services/download-service';
import { CreateDownloadFn, DownloadsV2State, GetDownloadsFn } from './downloadsV2.types';

export const initialState: DownloadsV2State = {
  downloads: [],
  isLoading: false,
  isError: false,
};

export enum DownloadActions {
  CREATE_DOWNLOAD = 'CREATE_DOWNLOAD',
  GET_DOWNLOADS = 'GET_DOWNLOADS',
}

const createDownloadHandler: CreateDownloadFn = async ({ userId, payload }) => {
  try {
    const data = await DownloadService.createDownload(userId, payload);
    return data;
  } catch (error) {
    throw new Error(`Error creating download: ${error}`);
  }
};

const getDownloadsHandler: GetDownloadsFn = async ({ userId }) => {
  try {
    const data = await DownloadService.getDownloads(userId);
    return data;
  } catch (error) {
    throw new Error(`Error creating download: ${error}`);
  }
};

export const createDownload = createAsyncThunk(DownloadActions.CREATE_DOWNLOAD, createDownloadHandler);
export const getDownloads = createAsyncThunk(DownloadActions.GET_DOWNLOADS, getDownloadsHandler);

export const downloadsSlice = createSlice({
  name: 'downloadsV2',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createDownload.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createDownload.fulfilled, (state, action) => {
        state.isLoading = false;
        state.downloads.push(action.payload);
      })
      .addCase(createDownload.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      })
      .addCase(getDownloads.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getDownloads.fulfilled, (state, action) => {
        state.isLoading = false;
        state.downloads = action.payload;
      })
      .addCase(getDownloads.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      });
  },
});
