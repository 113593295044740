import { pickAll } from 'ramda';
import { Rest } from 'src/api/rest';
import { API } from 'src/api/api';
import { IApplication } from 'src/types/core';
import { AttachmentPayload, AppData } from 'src/reducers/apps.types';
import { BaseService } from './base-service';

const invokeAppDataProps = (data: Partial<AppData & IApplication>): Pick<AppData & IApplication, keyof AppData> => {
  return pickAll(['id', 'title', 'organization_id', 'selected_cid_keys'], data);
};

export type MessageCenterIdsByApps = { app_id: string; message_center_id: string };

export class MongoInteractionService extends BaseService {
  static getAppData(appId: string): Promise<AppData> {
    return Rest.httpGet(API.MONGO_INTERACTION.GET_APP(appId), {
      errorTitle: '[API] Error fetch app info',
    }).promise;
  }

  static getListOfAppsData(apps: string[]): Promise<AppData[]> {
    return Rest.httpPost(API.MONGO_INTERACTION.GET_APPS, {
      body: { apps },
      errorTitle: '[API] Error fetch list of apps data info',
    }).promise;
  }

  static updateAppData(data: Partial<AppData & IApplication>): Promise<AppData | void> {
    if (!data.id) {
      return Promise.reject('no id');
    }
    return Rest.httpPut(API.MONGO_INTERACTION.PUT_APP(data.id), {
      body: invokeAppDataProps(data),
      errorTitle: '[API] Error update app info',
    }).promise;
  }

  static deleteAttachment(payload: AttachmentPayload) {
    return Rest.httpPost(API.MONGO_INTERACTION.DELETE_ATTACHMENT, {
      body: payload,
      errorTitle: '[API] Error Deleting Attachment',
    }).promise;
  }

  static updateUserUIState(userId: string, payload: Record<string, boolean>) {
    return Rest.httpPut(API.MONGO_INTERACTION.UPD_UI_STATE(userId), {
      body: payload,
      errorTitle: '[API] Error Updating ui_state',
    }).promise;
  }

  static getMessageCenterIdsByApps(apps: string[]): Promise<MessageCenterIdsByApps> {
    return Rest.httpPost(API.MONGO_INTERACTION.GET_MESSAGE_CENTER_IDS_BY_APPS, {
      body: { apps },
      errorTitle: '[API] Error fetching message center interaction IDs',
    }).promise;
  }
}
